import { $_ready, $_, Form, Request, Platform } from '@aegis-framework/artemis';

// Register the service worker
if (Platform.serviceWorkers ()) {
	navigator.serviceWorker.register ('service-worker' + '.js');
}

$_ready (() => {

	$_('.mailto').each ((element) => {
		const mail = element.href.replace (/\(dot\)/g, '.').replace (/\(at\)/g, '@').replace (window.location.href.replace (window.location.hash, ''), '');
		element.href =  `mailto:${mail}`;
		$_(element).find ('span').text (mail);
	});

	$_('.nav__menu-icon').click (function () {
		$_(this).closest ('ul').toggleClass ('nav__content-list--active');
	});

	$_('.nav__content-list__item').click (function () {
		if ($_(this).closest ('ul').hasClass ('nav__content-list--active')) {
			$_(this).closest ('ul').toggleClass ('nav__content-list--active');
		}
	});

	$_('.donate').click (() => {
		$_('.hidden form').get (0).submit ();
	});

	$_('[data-form]').submit (function (event) {
		event.preventDefault ();
		const form = this.dataset.form;
		const values = Form.values (form);
		const message = values.message.trim();

		// Check if the message is more than one word and more than 15 characters
		if (message.length < 15 || message.indexOf (' ') === -1) {
			$_('[data-form] [data-status="error"]').text ('Whoops! Your message is too short.');
			return;
		}

		Request.post (form, values).then ((response) => {
			if (response.status === 200) {
				this.reset ();
				$_('[data-form] [data-status="error"]').text ('');
				$_('[data-form] [data-status="success"]').text ('Your message has been sent! You’ll hear from me soon :)');
			} else if (response.status === 400) {
				$_('[data-form] [data-status="error"]').text ('There is some missing information, please fill out all the fields');
			} else {
				$_('[data-form] [data-status="error"]').text ('An error occurred, try later or you can also send me a message to my email: bianca@wafflemeido.art');
			}
		});
	});
});